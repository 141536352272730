// import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth'
import router from '@/router'
import Vue from 'vue'
import { uid } from 'uid'


const initial_state = () => ({ 
    current_shelve: 0,
    current_company_id: '',
    shelf_changed: false,
    logs: [],
    // shelves: 
    //     [ 
    //       // DETTA ÄR EN HYLLA:
    //       [ 
    //         // DETTA ÄR ETT HYLLPLAN:
    //         [ 
    //           // DETTA ÄR EN SLOT:
    //           [ 
    //             // DETTA ÄR EN PRODUKT:  1:B:4292075913309:30905217155165
    //             "" 
                      // {
                      //   uid: '',
                      // }
    //             // SLUT PÅ PRODUKT
    //           ],
    //           // SLUT PÅ SLOT
    //         ],
    //         // SLUT PÅ HYLLPLAN
    //       ]
    //     ]
    //   ,
      shelves: [],
      company: {
        data_sources: [],
        carriers: [],
        inventory_products: [],
        custom_scripts: [],
        days_valid_inventory_count: 0,
        default_shelflabel_sheet: {
          mm_height: 100,
          mm_width: 140,
          mm_margin: 2,
          rows: 2,
          cols: 2,
          rotation: 0,
        },
        check_inventory_threshold: 0,
        packages: [],
        print_server_address: '',
        printer_map: [],
        printers: [],
        // print_server_connected: false,
      },
      printserver_connected: false,
      last_connection: 0,
      last_ping: 0,
})


// ( fulfillment_location > section >  aisle > ) shelves > shelve > shelf > slot > z-slot?
const unload_listener = () => window.addEventListener('beforeunload', function () {
  console.log('Unloading listeners');
  if(printserver_listener) printserver_listener()
  if(printserver_timeout) clearTimeout(printserver_timeout)
  if(interval_check) clearInterval(interval_check)
  return undefined
});

const state = initial_state()

const initial_shelves = []
// const undoStates = []
// undoStates.push(JSON.stringify(state))
let printserver_listener, printserver_timeout, interval_check

const actions = {
  // async update_permissions({ commit }){
  //   const update_permissions = this._vm.$functions.httpsCallable('update_permissions')
  //   await update_permissions()
  //   commit('SET_COMPANY', { uses_fulfillment_orders: true })

  // },
  async developer({ state }){
    await firebase.firestore().collection('data_sources').doc(state.company.data_sources[0].id).collection('products').get().then(docs => {
      docs.forEach(doc => doc.ref.delete())
  })
  },
  async set_printserver_listener({ state, commit }){
    if(printserver_listener) printserver_listener()
    if(!printserver_timeout) clearTimeout(printserver_timeout)
    if(!interval_check) interval_check = setInterval( async () => {
      if(state.last_ping > new Date().getTime() - (1000 * 60 * 1)) return
      if(state.last_connection > new Date().getTime() - (1000 * 60 * 1)) return
      const ping = new Date()
      console.log('Requesting new handshake from interval check');
      commit('SET_STATE', { last_ping: ping.getTime() })
      await firebase.firestore().collection('printservers').doc(state.company.printserver_id).set({ ping: ping.toISOString() }, { merge: true })
    }, 10000)
    unload_listener()
    printserver_listener = firebase.firestore().collection('printservers').doc(state.company.printserver_id).onSnapshot(async doc => {
      const printserver = doc.data()
      if(!printserver.last_connection) return printserver_listener()
      const last_connection = new Date(printserver.last_connection).getTime()
      const last_ping = new Date(printserver.ping || null).getTime()
      if(last_connection < (new Date().getTime() - (1000 * 60 * 1))){
       if(last_ping < (new Date().getTime() - (1000 * 60 * 5))) {
          //   Request handshake if last handshake were +5 mins ago
          await doc.ref.set({ ping: new Date().toISOString() }, { merge: true })
        }
        console.log('setting timeout');
        printserver_timeout = setTimeout(() => {
          console.log('timed out:', state.last_connection === last_connection);
          if(state.last_connection === last_connection) commit('SET_STATE', { printserver_connected: false })
        }, 5000)
      } else commit('SET_STATE', { printserver_connected: true, last_connection })
      commit('SET_STATE', { last_ping, last_connection })
    })
  },
  async get_company_plan({ state, commit, dispatch }){
    console.log('is in x')
      // const company_id = state.current_company_id
      // const ds_id = typeof state.company.data_sources[0] === 'object' ? state.company.data_sources.filter(ds => ds.parent_company_id === company_id)[0].id : state.company.data_sources[0]
      console.log('DATA SOURCES', state.company.data_sources)
      const ds_ids = state.company.data_sources.map(a => a.id)
      const company_id = state.current_company_id
      if(!ds_ids.length) return 
      const get_store_plan = this._vm.$functions.httpsCallable('get_store_plan')
      const subscription_plans = await get_store_plan({ ds_ids, company_id }).then(res => res.data)
      for(const index in subscription_plans) commit('SET_DATA_SOURCE', {id: ds_ids[index], subscription_plan: subscription_plans[index].plan })
      if(!subscription_plans.find(plan => plan.master_store && plan.plan)){
        await firebase.auth().signOut() 
        dispatch('app/reset_state', {}, { root: true })
        router.push('/signin')
        commit('app/SET_SNACK_BAR', 'No active subscription', { root: true })
      }
  },
  async change_company_plan({ state, commit }, { plan_id }){
    const change_company_plan = this._vm.$functions.httpsCallable('change_company_plan')
    const company_id = state.current_company_id
    const ds_id = state.company.data_sources.filter(ds => ds.parent_company_id === company_id)[0].id
    const charge = await change_company_plan({ ds_id, company_id, plan_id }).then(res => res.data)
    // commit('app/SET_STATE', {awaiting_charge_id: charge.id}, { root: true })
    // console.log(charge);
    if(charge.deleted) commit('SET_DATA_SOURCE', {id: ds_id, subscription_plan: charge.plan })
    return charge 

  },
  async perform_log_action({ commit, state }, { log }){
    const [ , data_source_id,  ] = ( log.identifier || log.item_uuid || log.order_uuid ).split(':')
    const store_ref = firebase.firestore().collection('data_sources').doc(data_source_id)
    const store = state.company.data_sources.find(ds => ds.id === data_source_id)
    if(log.type === 'blocked_item'){
        const blocked_items = store.blocked_items.filter(item => item.item_uuid !== ( log.identifier || log.item_uuid ))
        commit('SET_DATA_SOURCE', {id: data_source_id, blocked_items})
        await store_ref.set({ blocked_items }, {merge: true})
        
    } else if(log.type === 'blocked_order')  {
        const blocked_orders = store.blocked_orders.filter(item => item.order_uuid !== ( log.identifier || log.order_uuid ))
        commit('SET_DATA_SOURCE', {id: data_source_id, blocked_orders})
        await store_ref.set({ blocked_orders }, {merge: true})
    }
    if(log.id){
      await store_ref.collection('logs').doc(log.id).delete()
      commit('REMOVE_LOG', log.id)
    }
  },
  async retrieve_logs({ state, commit, rootState }){
    firebase.firestore().collection('users').doc(rootState.user.user.uid).set({
      companies: rootState.user.userProfile.companies.map(company => {
        return company.id === state.current_company_id ? { ...company, logs_read_at: new Date().toISOString()} : company
      })
    }, { merge: true})
    const logs = await Promise.all(
      state.company.data_sources.map(ds => 
          firebase.firestore().collection('data_sources').doc(ds.id).collection('logs').get()
              .then(docs => {
                  const data = []
                  docs.forEach(doc => data.push(doc.data()))
                  return data
              })
      )
  ).then(p => [].concat(...p))
  logs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    commit('SET_STATE', { logs })
  },
  async remove_user({ state, commit } , { id, is_invitation }){
    try {
      const remove_user_from_company = this._vm.$functions.httpsCallable('remove_user_from_company')
      await remove_user_from_company({
        company_id: state.current_company_id,
        user_id: id,
        is_invitation
      })
      commit('SET_COMPANY', { 
        [is_invitation ? 'invitations': 'users'] : state.company[is_invitation ? 'invitations' : 'users'].filter(user => user.id !== id)
      })
    } catch (e) {
      commit('app/SET_SNACK_BAR', e.message, {root: true})
    }
  },
  async get_fulfillment_locations({ commit, state }, ds_id){
    const get_fulfillment_locations = this._vm.$functions.httpsCallable('get_fulfillment_locations')
    const locations = await get_fulfillment_locations( {
      company_id: state.current_company_id,
      ds_id,
    }).then(res => res.data)
    commit('SET_DATA_SOURCE', { id: ds_id, locations })
  },
  async save_data_source({ commit, state }, { id, ...payload }){
    // const save_data_source = this._vm.$functions.httpsCallable('save_data_source')
    const company_id = state.current_company_id
    // await save_data_source(payload)
    await firebase.firestore().collection('data_sources').doc(id).set(payload, { merge: true })
    commit('SET_DATA_SOURCE', {id, ...payload})
    if(state.company.data_sources.some(ds => ds.fulfilling_company_id !== company_id && ds.parent_company_id !== company_id)){
      const data_sources = state.company.data_sources
      .filter(ds => ds.fulfilling_company_id !== company_id && ds.parent_company_id !== company_id)
      await firebase.firestore().collection('companies').doc(company_id).set({ 
        data_sources: data_sources.map(ds => ds.id)
      }, { merge: true })
      commit('SET_COMPANY', { data_sources })
    }
  },
  async delete_auto_carrier({ commit }, {ds_id }){
    const delete_auto_carrier = this._vm.$functions.httpsCallable('delete_auto_carrier')
    try {
      const auto_carrier_services = await delete_auto_carrier({
        ds_id,
      }).then(r => r.data)
      commit('SET_DATA_SOURCE', { id: ds_id, auto_carrier_services })
    } catch (e) {
      commit('app/SET_SNACK_BAR', e.message, { root: true })
    }
  },
  async connect_auto_carrier({ commit }, {ds_id }){
    // const { id, name, type } = auto_carrier_service
    const connect_auto_carrier = this._vm.$functions.httpsCallable('connect_auto_carrier')
    try {
      const auto_carrier_services = await connect_auto_carrier({
        ds_id,
        // auto_carrier_service: { id, name, type },
      }).then(r => r.data)
      commit('SET_DATA_SOURCE', { id: ds_id, auto_carrier_services })
    } catch (e) {
      commit('app/SET_SNACK_BAR', e.message, { root: true })
    }
  },
  async add_data_source({ commit, state }, store){
    const add_data_source = this._vm.$functions.httpsCallable('add_data_source')
    await add_data_source({
      ...store,
      company_id: state.current_company_id,
    }).then(res => commit('SET_COMPANY',  res.data ))
    .catch(e => commit('app/SET_SNACK_BAR', e.message, { root: true }))
    
  },
  async save_company({ state }, keys){
    const data = {}
    for(const key of keys) data[key] = state.company[key]
    await firebase.firestore().collection('companies').doc(state.current_company_id).set( { ...data } , { merge: true })
  },
 async save_shelf({ state, commit }){
   try {
    // const set_warehouse_layout = this._vm.$functions.httpsCallable('save_company')
    // await set_warehouse_layout({ warehouse_layout: state.shelves, company_id: state.current_company_id })
    await firebase.firestore().collection('companies').doc(state.current_company_id).set({
      warehouse_layout: state.shelves
    }, { merge: true })
    commit('SAVE_CHANGES')
   } catch (e){
     commit('app/SET_SNACK_BAR', e.message, { root: true})
   }
 },
 async set_company({ commit, dispatch }, id){
  commit('app/SET_STATE', { loading_message: 'Loading store...' }, { root: true })  
  const get_company_profile = this._vm.$functions.httpsCallable('get_company_profile')
  const company = await get_company_profile(id).then(res => res.data)
  const shelves = company.warehouse_layout
  commit('SET_COMPANY', company)
  commit('SET_COMPANY_ID', id)
  commit('SET_SHELVES', shelves)
  dispatch('get_company_plan')
  if(company.printserver_id) dispatch('set_printserver_listener')
  initial_shelves[0] = JSON.stringify(state.shelves)
 },
//  async disconnect_printer({ commit, state }){
//   commit('SET_COMPANY', { print_server_address: '', print_server_connected: false})
//   await firebase.firestore().collection('companies').doc(state.current_company_id).set({ print_server_address: '' }, {merge: true})
//  },
//  async save_printers({state}){
//   await firebase.firestore().collection('companies').doc(state.current_company_id).set({ printer_map: state.company.printer_map }, {merge: true})
//  },
 async request_parcel({ rootState, dispatch, commit, state, rootGetters }, index){
  let log = '' 
  try { 
    let loops = 0 
    await new Promise((resolve, reject) => {
      const interval = setInterval(() => {
      console.log('loops', loops)
      if(rootState.product.products.length){
        clearInterval(interval)
        resolve(true)
      }
      ++loops
      if(loops > 15) {
        reject('Products not loading')
        clearInterval(interval)
      }
      }, 1000 )}).catch(e => {throw new Error(e)})
    const o = rootState.order.order_bucket[index]
    log += o ? `Order: ${o.name}` : 'NO ORDER: ```' + (rootState.order.order_bucket || []).length + '```'
    const sf_products = await Promise.all(o.line_items.map(item => 
      rootGetters['product/get_product'](item.uuid) || 
      dispatch('product/get_specific_products', {uuids: [item.uuid]}, {root: true})
      .then(products => 
        products.find(p => p.uuid === item.uuid)
        )
      ))
    const line_items = o.line_items.map((item, p_index) => {
      const inventory_item_id = sf_products[p_index] && sf_products[p_index].inventory_item_id
      return {
        ...item,
        inventory_item_id,
      }
    })
    log += `\n Loops: ${loops} \n\nPre book_shipment`
      const order = { 
        ...o,
        line_items }
      const book_shipment = this._vm.$functions.httpsCallable('book_shipment')
      let tries = 0
      const labels = await new Promise((res, rej) => {
        const test_book = () => book_shipment({
          parcel: order, 
          company_uid: state.current_company_id, 
        })
        .then(r => res(r.data))
        .catch(e => {
          const err = e.message[0] === '{' ? JSON.parse(e.message) : e.message
          if(!err.message.includes('internal') || tries > 2) return rej(e)
          else {
            log += `\n Trial ${tries}`
            dispatch('app/submit_error', { e, within: 'request_parcel:catch' + log}, {root: true})
            ++tries
            return test_book()
          }
        })
        test_book()
      })
      console.log('tries', tries)
      // const labels = await book_shipment({
      //   parcel: order, 
      //   company_uid: state.current_company_id, 
      // }).then(res => res.data)
      log += `Post book_shipment index = ${index}\n\n`
      log += 'Order: ```' + JSON.stringify(rootState.order.order_bucket[index] || {no: 'order'}, undefined, 3) + '```'
      // commit('order/SET_SF_DATA', {index, data: {parcel_data: shipment.carrier_response}}, {root: true})
      commit('order/SET_SF_DATA', {index, data: { labels }}, {root: true})
      await dispatch('order/save_order_bucket', {}, {root: true})
    } catch (e) {
      console.log(e)
      const err = e.message[0] === '{' ? JSON.parse(e.message) : e.message
      console.log('err', err)
      if(!err.error_fields?.length) dispatch('app/submit_error', { e, within: 'request_parcel' + log}, {root: true})
      commit('order/SET_PARCEL_ERROR', {
        index,
        message: err.message,
        error_fields: err.error_fields,
      }, {root: true})
    }
 },
  async submit_print_job({ state }, { pdf_ref, description, media, carrier_id }){
    const submit_print_job = this._vm.$functions.httpsCallable('submit_print_job')
    const printserver_id = state.company.printserver_id
    await submit_print_job({ printserver_id, pdf_ref, carrier_id, description, media })
  },
}

const mutations = {
  INIT_COMPANY(state, { company }){
    const { warehouse_layout, ...rest } = company
    rest.data_sources = rest.data_sources.map(ds => {
      if(typeof ds !== 'object') return { id: ds }
      else return ds
    })
    console.log('INIT COMPANY', rest)
    Object.keys(rest).forEach(key => 
      Vue.set(state.company, key, rest[key])
      )
    Vue.set(state, 'current_company_id', company.id )
    Vue.set(state, 'shelves', warehouse_layout)
    initial_shelves[0] = JSON.stringify(warehouse_layout)
    
  },
  SET_DATA_SOURCE(state, { id, ...payload}){
    const index = state.company.data_sources.findIndex(ds =>  ds.id === id)
    // Object.keys(payload).forEach(key => state.company.data_sources[index][key] = payload[key])
    Object.keys(payload).forEach(key => {
      Vue.set(state.company.data_sources[index], key, payload[key])
    }
      )
  },
  UPDATE_SHIPPING_MAP(state, { store_id, map_index, map }){
    const store_index = state.company.data_sources.findIndex(d => d.id === store_id)
    Vue.set(state.company.data_sources[store_index].shipping_map, map_index, map)
  },
  SET_MULTIPLE_SLOTS(state, items){
    for(const item of items){
      const { shelve, shelf, slot, product, ...rest } = item
      const original = state.shelves[shelve].shelfs[shelf].slots[slot].products[product]
      Vue.set(state.shelves[shelve].shelfs[shelf].slots[slot].products, product, { ...original, ...rest })
    }
  },
  SET_UNPRINTED_PAST(state, { shelve, shelf, slot, product}){
    for(let shelve_index = shelve.from; shelve_index < (shelve?.to || state.shelves.length); ++shelve_index){
      for(let shelf_index = (shelf?.from || 0); shelf_index < (shelf?.to || state.shelves[shelve_index].shelfs.length); ++shelf_index){
        for(let slot_index = (slot?.from || 0) ; slot_index < (slot?.to || state.shelves[shelve_index].shelfs[shelf_index].slots.length); ++slot_index){
          for(let product_index = (product?.from || 0); product_index < (product?.to || state.shelves[shelve_index].shelfs[shelf_index].slots[slot_index].products.length); ++product_index){
            const original = state.shelves[shelve_index].shelfs[shelf_index].slots[slot_index].products[product_index]
            if(original?.uid && original.printed) Vue.set(state.shelves[shelve_index].shelfs[shelf_index].slots[slot_index].products, product_index, { ...original, printed: false})
          }
        }
      }
    }
  },
  SET_SHELVES(state, shelves){
    state.shelves = shelves
  },
  SET_COMPANY(state, payload){
    // Object.keys(payload).forEach(key => state.company[key] = payload[key])
    Object.keys(payload).forEach(key => 
      Vue.set(state.company, key, payload[key])
      // state.company[key] = payload[key]
      )
  },
  SET_COMPANY_ID(state, id){
    state.current_company_id = id
  },
  SET_STATE(state, payload){
    for(const key in payload){
      Vue.set(state, key, payload[key])
    }
  },
  REMOVE_LOG(state, id){
    const logs = state.logs.filter(log => log.id !== id)
    Vue.set(state, 'logs', logs)
  },
  SET_CURRENT_SHELVE(state, payload){
    state.current_shelve = Number(payload)
  },
  CHANGE_SHELF(state){
    state.shelf_changed = true
    
  },
  ADD_SHELF(state, shelfIndex){
    const shelf = {id: uid(), slots: [ {id: uid(), products: [ {id: uid(), uid: ''} ] } ] }
    state.shelves[state.current_shelve].shelfs.splice(shelfIndex + 1, 0, shelf)
    state.shelf_changed = true
  },
  REMOVE_SHELF(state, shelfIndex){
    state.shelves[state.current_shelve].shelfs.splice(shelfIndex, 1)
    state.shelf_changed = true
  },
  DISCARD_CHANGES(state){
    state.shelves = JSON.parse(initial_shelves[0])
    state.shelf_changed = false
  },
  SAVE_CHANGES(state){
    initial_shelves[0] = JSON.stringify(state.shelves)
    state.shelf_changed = false
  },
  RESET_STATE(state){
    for(const key in initial_state()){
      Vue.set(state, key, initial_state()[key])
    }
  }
}

const getters = {
  // get_mapped_printer: (state, getters, rootState) => ({ media, carrier_type, format }) => {
  //   return state.company.printer_map.find(mapped_printer => {
  //     const app_printer = rootState.app.config.label_formats[carrier_type]
  //       .find(p => {
  //           return p.alternative_titles.concat([p.title]).indexOf(media) !== -1
  //         })
  //     const printer_titles = app_printer.alternative_titles.concat([app_printer.title])
  //     return mapped_printer.target === format
  //       && printer_titles.indexOf(media) !== -1
  //     })
  // },
  get_used_label_formats: (state) => {
    return state.company.data_sources.reduce((tot, ds) => tot
      .concat(ds.shipping_map
        .map(p => p.label.type)
        .concat((ds.auto_carrier_services?.active_services || []).map(p => p.label.type))
      )
    , [])
  },
  get_unprinted_shelflabels: (state) => {
    const unprinted = []
    for(const shelve in state.shelves){
      for(const shelf in state.shelves[shelve].shelfs){
        for(const slot in state.shelves[shelve].shelfs[shelf].slots){
          for(const product in state.shelves[shelve].shelfs[shelf].slots[slot].products){
            if(state.shelves[shelve].shelfs[shelf].slots[slot].products[product].uid && !state.shelves[shelve].shelfs[shelf].slots[slot].products[product].printed) unprinted.push({
              product_uid: state.shelves[shelve].shelfs[shelf].slots[slot].products[product].uid,
              shelve,
              shelf,
              slot,
              product
            })
          }
        }
      }
    }

    // state.shelves.forEach(shelve => 
    //   shelve.shelfs.forEach(shelf => 
    //     shelf.slots.forEach(slot => 
    //       slot.products.forEach(product => 
    //         { if(!product.printed && product.uid) unprinted.push({
    //           product_uid: product.uid, 
    //           // shelve, 
    //           // shelf,
    //           // slot,
    //           // product,
    //           // product_id: product.id, 
    //           // slot_id: slot.id,
    //           // shelf_id: shelf.id,
    //           // shelve_id: shelve.id,
    //         }) }
    //       )
    //     )
    //   )
    // )
    return unprinted
  },
  get_main_store: (state) => {
    return state.company.data_sources.find(ds => ds.parent_company_id === state.current_company_id)
  },
  get_plan: (state, getters, rootState) => {
    const main_ds = state.company.data_sources
    .find(ds => ds.parent_company_id === state.current_company_id)
    if(!main_ds) return null
    const plan = main_ds.subscription_plan
    if(!main_ds.subscription_plan) return null
    const scopes = rootState.app.config.plan_limits.reduce((tot, scope) => {
        return {
          ...tot,
          [scope.key]: scope[plan.title]
        }
      }, {})
      return { ...plan, ...scopes}
  },
  get_printing_possible: (state) => {
    return (!!state.company.carriers.length 
      && state.company.data_sources
        .some(ds => ds.fulfilling_company_id === state.current_company_id)) 
        || state.company.data_sources
        .some(ds => ds.fulfilling_company_id === state.current_company_id && ds.uses_shopify_labels) 
  },
  get_printserver_connected: (state) => {
    return state.company.printserver_id && state.printserver_connected
  },
  shelfed_products: (state, getters, rootState) => {
    const shelfed_product_ids = []
    state.shelves.forEach(shelve => 
      shelve.shelfs.forEach(shelf => 
        shelf.slots.forEach(slot => 
          slot.products.forEach(product => 
            { if(product.uid) shelfed_product_ids.push(product.uid) }
          )
        )
      )
    ) 
    const shelfed_products = rootState.product.products.filter(product => shelfed_product_ids.indexOf(product.uuid) !== -1)
    return shelfed_products
  },
  unshelfed_products: (state, getters, rootState) => (shelfIndex, slotIndex, productIndex) => { 
    const current_product_id = state.shelves[state.current_shelve].shelfs[shelfIndex].slots[slotIndex].products[productIndex].uid
    const current_product = rootState.product.products.filter(product => product.uuid === current_product_id)
    const shelfed_product_ids = []
    state.shelves.forEach(shelve => 
      shelve.shelfs.forEach(shelf => 
        shelf.slots.forEach(slot => 
          slot.products.forEach(product => 
            { if(product.uid) shelfed_product_ids.push(product.uid) }
          )
        )
      )
    ) 
    const unshelfed_products = rootState.product.products.filter(product => shelfed_product_ids.indexOf(product.uuid) === -1)
    if(!current_product.length && current_product_id) return [{uuid: current_product_id, title: 'Deleted product'}].concat(unshelfed_products)
    return current_product.concat(unshelfed_products)
  },
  get_amount_unmapped_products: (state, getters, rootState) => {
    const shelfed_product_ids = []
    state.shelves.forEach(shelve => 
      shelve.shelfs.forEach(shelf => 
        shelf.slots.forEach(slot => 
          slot.products.forEach(product => 
            { if(product.uid) shelfed_product_ids.push(product.uid) }
          )
        )
      )
    ) 
    const unshelfed_products = rootState.product.products.filter(product => shelfed_product_ids.indexOf(product.uuid) === -1)
    return unshelfed_products.length
  },
get_product_location_by_uuid: (state) => (uuid) => {
  // let position
  for(const shelve in state.shelves){
    for(const shelf in state.shelves[shelve].shelfs){
      for(const slot in state.shelves[shelve].shelfs[shelf].slots){
        for(const product in state.shelves[shelve].shelfs[shelf].slots[slot].products){
          if(state.shelves[shelve].shelfs[shelf].slots[slot].products[product].uid === uuid) return {
            shelve,
            shelf,
            slot,
            product
          }
        }
      }
    }
  }
}
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
