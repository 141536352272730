import Vue from 'vue'
import axios from 'axios'
import { PDFDocument,  } from 'pdf-lib'
// import { jsPDF } from "jspdf";
// import firebase from 'firebase'
// import 'firebase/firestore';

const initial_state = () => ({ 
    
})

const state = initial_state()

const actions = {
  async get_ac_items({ commit }, { warehouse, order_number }){
    const data = await axios.get(`https://script.google.com/macros/s/AKfycbwpIIeYal4ZAmEiv6uSvsi30JGMPZCTq9evdK4uUW05fC7OCzs/exec?dest=get_ac&warehouse=${warehouse}&order_number=${order_number || false}`).then(res => res.data)
    commit('SET_STATE', { ...data })
    
    // const file = new Blob([cert_prints[0]], {type: 'application/pdf'})
    // console.log(file);
    // const url = URL.createObjectURL(file)
  },
  async set_printed({ state }, { ids }){
    console.log(state.x)
    const data = await axios.get(`https://script.google.com/macros/s/AKfycbwpIIeYal4ZAmEiv6uSvsi30JGMPZCTq9evdK4uUW05fC7OCzs/exec?dest=set_printed&ids=${ids.join(',')}`).then(res => res.data)
    console.log('RES DATA', data)
    // commit('SET_PRINTED', ids)
  },
  async handle_ac({ commit, rootState }, { type, method, ids }){
    let index = 0
    const queries = type === 'certificates' ? ids 
      : type === 'passports' ? ids.reduce((tot, id) => {
      if((tot[index] || []).length === 6) ++index
      tot[index] = tot[index] || []
      tot[index].push(id)
        return tot
    }, []).map(i => 
      (`?orderId=${i.join('&orderId=')}`)
      )
    : ids // .map(i => i.photo_id)
    const results = await Promise.allSettled(
      queries.map(c => 
        axios.post(`${process.env.VUE_APP_SERVER_URL}/get_ac_items`, {
          url: type === 'photos' ? `https://api.avatarcountry.com/api/v1/photos/${c.photo_id}/image`
          : `https://api.avatarcountry.com/api/v1/orders/${ type === 'certificates' ? 'citizenship' : 'passports'}/${c}`,
          send_to_print: (method === 'print'),
          target: type,
          printserver_id: rootState.warehouse.company.printserver_id,
        },
        { 
          responseType: 'arraybuffer',
        })
      )
    )

    // Handle rejections
    results.forEach((result, index) => {
      if (result.status === 'rejected') {
        commit('app/SET_SNACK_BAR', `Error fetching ${type} ${queries[index].id || queries[index]}: ${result.reason.message}`, { root: true })
      }
    })

    // Filter successful results
    const prints = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value.data)
      commit('SET_STATE', { [`${type}-prints`]: prints })
        if(method !== 'open'){return}
        if(type === 'photos') {
          const ret = await Promise.all(prints.map(async (print, index) => {
            const url = URL.createObjectURL(new Blob([print], {type: 'image/jpg'}))
            const get_img = () => new Promise(resolve => {
              const img = new Image();
              img.onload = () => {
                const width = img.width;
                const height = img.height;
                resolve({ width, height });
              };
              img.src = url
            })
            const { width, height } = await get_img()
            return {
              arraybuffer: print,
              url,
              is_landscape: width > height,
              shopify_name: queries[index].shopify_name
            }
          })
        )
        return ret
        }
          const pdfDoc = await PDFDocument.create()
          
          const actions = prints.map(async (print) => {
            const pdf = await PDFDocument.load(print)
            const [page] = await pdfDoc.copyPages(pdf, [0])
            pdfDoc.addPage(page)
          })
          await Promise.all(actions)
          const d = await pdfDoc.save()
          const file = new Blob([d.buffer], {type: 'application/pdf'})
            const url = URL.createObjectURL(file)
            window.open(url)           
    }
}

const mutations = {
   
      SET_STATE(state, payload){
        for(const key in payload){
          Vue.set(state, key, payload[key])
        }
      },
      RESET_STATE(state){
        for(const key in initial_state()){
          Vue.set(state, key, initial_state()[key])
        }
      },
      // SET_PRINTED(state, ids){
      //   for(const id of ids){
      //     const [order_number, photo_id] = id.split(':')
      //     const index = state.photos.findIndex(p => p.order_number === order_number)
      //     Vue.set(state.photos, index, state.photos[index].order)
      //   }
      // }
}

const getters = {
  
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
